import "./App.css";
import { useRef, useState } from "react";

const {
  S3Client,
  PutObjectCommand,
  // CreateMultipartUploadCommand,
  // UploadPartCommand,
} = require("@aws-sdk/client-s3");

// Set the AWS Region
const REGION = process.env.REACT_APP_MINIO_REGION;

function App() {
  const [image, setImage] = useState();
  const Bucket = process.env.REACT_APP_MINIO_BUCKET;

  const aws = useRef(
    new S3Client({
      region: REGION,
      endpoint: process.env.REACT_APP_MINIO_ENDPOINT,
      credentials: {
        accessKeyId: process.env.REACT_APP_MINIO_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_MINIO_SECRET_KEY,
      },
    })
  );

  console.log("aws", aws.current);

  const createImage = function (files) {
    console.log(files, files.length);
    if (files.length > 0) {
      [...files].map((file) => {
        setImage((prev) => ({
          files: prev?.files ? [...prev?.files, file] : [file],
        }));
        return file;
      });
    }
  };

  async function onFileChange(e) {
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length) return;

    console.log("files", files);
    createImage(files);
  }

  const removeImage = function (e) {
    console.log("Remove clicked");
    setImage(undefined);
  };

  const uploadImage = async function (e) {
    console.log("Upload clicked", image?.files);

    try {
      if (image?.files?.length > 0) {
        const files = image.files;
        // let partNumbers = {};
        // files.map(async (file) => {
        //   let [partNumber, fileType] = file.name.split("-").pop()?.split(".");
        //   let regexPartNumber = /\d\d\d\d/g;
        //   console.log("partNumber123", partNumber, fileType);
        //   if (partNumber && fileType && regexPartNumber.test(partNumber)) {
        //     if (partNumbers?.partNumber) {
        //       console.log("Trùng thứ tự!");
        //       return;
        //     }
        //     console.log("partNumber", partNumber, fileType, file.type);
        //   }

        //   if (!partNumber) {
        //     alert("Nhập thêm số thứ tự trong tên file! Ví dụ: abc-0001.mp4");
        //     return;
        //   }
        //   if (!fileType) {
        //     alert("Nhập thêm loại file trong tên file! Ví dụ: abc-0001.mp4");
        //     return;
        //   }
        // });
        // return;
        // const multiPartName =
        //   process.env.REACT_APP_MINIO_FOLDER + "/" + files[0].name;
        // let multipartResponse = await aws.current.send(
        //   new CreateMultipartUploadCommand({
        //     Bucket,
        //     Key: multiPartName,
        //   })
        // );
        // if (multipartResponse) {
        // console.log("multipartResponse", multipartResponse);
        files.map(async (file) => {
          // let partNumber = file.name;
          const fileKey = process.env.REACT_APP_MINIO_FOLDER + "/" + file.name;
          const uploadParams = {
            Bucket,
            Key: fileKey,
            Body: file,
          };
          console.log("uploadParams", uploadParams);
          try {
            // let response = await aws.current.send(
            //   new UploadPartCommand({
            //     Bucket,
            //     UploadId: multipartResponse.UploadId,
            //     Body: file,
            //     PartNumber: 1,
            //     Key: "plit-file-" + multipartResponse.Key,
            //   })
            // );
            let response = await aws.current.send(
              new PutObjectCommand({
                ...uploadParams,
              })
            );
            console.log("Successfully uploaded photo.", response);
          } catch (err) {
            return console.log(
              "There was an error uploading your photo: ",
              err.message
            );
          }
        });
        // }
      }
    } catch (err) {
      console.log("error", err);
      if (!image.file) {
        return alert("Choose a file to upload first.");
      }
    }

    // Get the presigned URL
    // const response = await axios({
    //   method: "GET",
    //   url: `${process.env.REACT_APP_MINIO_ENDPOINT}/${process.env.REACT_APP_MINIO_BUCKET}/`,
    //   headers: {
    //     Authorization:
    //       "AWS4-HMAC-SHA256 Credential=AKIAWF626CMVR3ON5FF4/20221227/us-east-1/execute-api/aws4_request, SignedHeaders=host;x-amz-date, Signature=078410791de9d4cc4c916e2bfe456af10f7ac2968c3d41fed043f690b824bd1d",
    //   },
    // });
    // console.log("Response: ", response);
    // console.log("Uploading: ", image);
    // let binary = atob(image.split(",")[1]);
    // let array = [];
    // for (var i = 0; i < binary.length; i++) {
    //   array.push(binary.charCodeAt(i));
    // }
    // let blobData = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
    // console.log("Uploading to: ", response.uploadURL);

    // const result = await fetch(response.uploadURL, {
    //   method: "PUT",
    //   body: blobData,
    // });
    // console.log("Result: ", result);
    // // Final URL for the user doesn't need the query string params
    // let uploadURL = response.uploadURL.split("?")[0];
  };

  const renderFileName = () => {
    return image?.files.map((file) => <p>{file.name}</p>);
  };

  return (
    <div className="App" style={{ padding: "50px" }}>
      <h1>S3 Uploader Test</h1>

      <div>
        <h2>Select an image</h2>
        <input type="file" multiple onChange={onFileChange} accept="*" />
      </div>
      <div>
        {/* <img style={{ width: "300px" }} src={image?.base64?.[0]} alt="img" /> */}
        <button onClick={removeImage}>Remove image</button>
        <button onClick={uploadImage}>Upload image</button>
        <div>{image?.files?.length > 0 ? renderFileName() : null}</div>
      </div>
      <h2>Success! Image uploaded to bucket.</h2>

      {/* <input onChange={onChange} type="file" placeholder="Upload file" />
      <button onClick={onSendAws}>Send</button> */}
    </div>
  );
}

export default App;
